<template>
  <NuxtChild />
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import { Route, NavigationGuardNext } from 'vue-router';

@Component
export default class DirectoryById extends Vue {
  created(): void {
    console.log(this.$route.params.practiceAreaGroup);
    console.log(this.$route.params.practiceArea);
  }

  beforeRouteEnter(_to: Route, _from: Route, next: NavigationGuardNext): void {
    // FIXME: エラーが出てトップにリダイレクトされるも表示がされない
    next('/');
  }
}
</script>
